.pro-sidebar-inner {
    background-color: #ffff !important;
    box-shadow: 0 4px 0 rgba(26, 114, 221, 0.1), 0 4px 15px 0 rgba(26, 114, 221, 0.1);
}

.pro-icon-wrapper {
    background-color: #ffff !important;
    border-radius: 4px !important;
}

.pro-inner-list-item {
    background-color: #ffff !important;
}